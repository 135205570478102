$(function() {
  var $body     = $('body'),
    $header     = $('#header'),
    $navigation = $('#navigation'),
    $button     = $('#navigation-button'),
    $links      = $('.js-navigation-link'),
    was_mobile = $button.css('display') === 'none' ? false : true,
    now_mobile = was_mobile
    scrollTop = 0,
    didResize = true;

  if (!$header.length) return true;

  $window.on('resize', function () {
    didResize = true;
  });

  $button.on('click', function(event) {
    event.preventDefault();
    event.stopPropagation();

    if (!now_mobile && !was_mobile) return;

    var navigation_visible = $navigation.attr('aria-hidden') === 'true' ? false : true;

    $button.attr('aria-expanded', !navigation_visible);
    $navigation.attr('aria-hidden', navigation_visible);
    $header.toggleClass('l-header--navigation-expanded', !navigation_visible);

    if(navigation_visible) {
      $body.removeClass('no-scroll').css('top', '');
      $window.scrollTop(scrollTop);
      $navigation.attr('tabindex', '-1');
    }
    else {
      scrollTop = $window.scrollTop();
      $body.addClass('no-scroll').css('top', -scrollTop + 'px');
      $navigation.removeAttr('tabindex');
    }
  });

  $links.on('click', function(e) {
    if (now_mobile && $navigation.attr('aria-hidden') === 'false') {
      $button.trigger('click');
    }
  });

  $(document).on('click focusin', function(e) {
    if (now_mobile && $navigation.attr('aria-hidden') === 'false' && $(e.target).closest('#header').length == 0) {
      $button.trigger('click');
    }
  });

  if (now_mobile) {
    $navigation.attr('aria-hidden', true);
    $navigation.attr('tabindex', '-1');
  }
  else {
    $navigation.attr('aria-hidden', false);
  }

  (function loop () {
    requestAnimationFrame(loop);

    if (didResize) {
      now_mobile = $button.css('display') === 'none' ? false : true;

      if(now_mobile && !was_mobile) {
        $navigation.attr('aria-hidden', true);
        $navigation.attr('tabindex', '-1');
      }

      if(!now_mobile && was_mobile) {
        var navigation_visible = $navigation.attr('aria-hidden') === 'true' ? false : true;
        if (navigation_visible) {
          $button.trigger('click');
        }

        $navigation.attr('aria-hidden', false);
        $navigation.removeAttr('tabindex');
      }

      was_mobile = now_mobile;
      didResize = false;
    }
  })();
});